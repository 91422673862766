<template>
	<div>
		<CRow>
			<CCol md="12">
				<CCard>
					<CCardHeader>
						<CRow>
							<CCol md="8">
								<h4>Edit Konfigurasi Dokumen Permohonan </h4>
								<small>{{tpb_nama}}</small>
							</CCol>
						</CRow>
					</CCardHeader>
					<CForm v-on:submit.prevent="storeProcess" class="fwdwd">
						<CCardBody>
							<table class="table table-sm table-bordered">
								<thead class="bg-dark text-white">
									<tr>
										<th>No</th>
										<th>Nama Dokumen Permohonan</th>
										<th>Urutan</th>
										<td><div class="btn btn-sm btn-success" v-on:click="addRowDokumen"><i class="fa fa-plus"></i></div></td>
									</tr>
								</thead>
								<tbody>
									<template>
										<tr v-if="loadingTable">
											<td colspan="7"><i class="fa fa-spin fa-refresh"></i> Loading...</td>
										</tr>
										<tr v-for="(row_data, key_dokumen_permohonan) in permohonan_dokumen" :key="key_dokumen_permohonan" v-else>
											<td>
												{{key_dokumen_permohonan+1}}
											</td>
											<td>
												
												<v-select :options="list_dokumen_permohonan" label="dpp_nama" v-model="row_data.dokumen"></v-select>
											</td>
											<td>
												<input type="number" class="form-control" v-model="row_data.urutan">
											</td>
											<td>
												<div class="btn btn-sm btn-danger" v-on:click="deleteRow(key_dokumen_permohonan, row_data)"><i class="fa fa-minus"></i></div>
											</td>
										</tr>
									</template>
								</tbody>
							</table>
						</CCardBody>
						<CCardFooter>
							<div class="text-right">
								<button type="submit" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
							</div>
						</CCardFooter>
					</CForm>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>
<script>
const null_array = [];
	export default {
		name: "EditTujuanBenih",
		data() {
			return {
				idTujuanBenih: this.$route.params.idTujuanBenih,
				permohonan_dokumen: [],
                paging: [],
                show_data_to: '',
                show_data_from: '',
                total_data: '',
                curent_page: '',
                loadingTable: true,
				list_dokumen_permohonan: '',
				tpb_nama: '',
				deleted: [],
			};
		},
		methods: {
			storeProcess: function () {
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
					// allowOutsideClick: false,
				});
				var FormData = require('form-data');
				var data = new FormData();
				data.append('tujuan_penggunaan_benih_id', this.idTujuanBenih);
				data.append('permohonan_dokumen', JSON.stringify(this.permohonan_dokumen));
				data.append('deleted', JSON.stringify(this.deleted));
				var config = {
					method: 'post',
					url: this.apiLink + "api/crud/tujuan_penggunaan_benih/save_dokumen_permohonan",
					headers: { 
					'Authorization': "Bearer " + this.access_token,
					},
					data : data
				};
				axios(config)
				.then(function (response) {
				var res_ajukan = response.data;
				var res_ajukan_data = res_ajukan.data;
					if (res_ajukan.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_ajukan.data.message
						});
					}
					else{
						let timerInterval;
						Swal.fire({
							icon: "success",
							title: "Berhasil",
							text: "Dokumen Permohonan Berhasil Disimpan.",
							timer: 2000,
							timerProgressBar: true,
							showCancelButton: false,
							showConfirmButton: false,
							didOpen: () => {
								timerInterval = setInterval(() => {
									const content = Swal.getContent();
									if (content) {
										const b = content.querySelector("b");
										if (b) {
											b.textContent = Swal.getTimerLeft();
										}
									}
								}, 100);
							},
							willClose: () => {
								clearInterval(timerInterval);
							},
						}).then((result) => {
							/* Read more about handling dismissals below */
							if (result.dismiss === Swal.DismissReason.timer) {
								window.location.reload();
							}
						});
					}
				});
			},
			addRowDokumen(){
				this.permohonan_dokumen.push({
					id:'',
	                dokumen: '',
		            urutan: ''
	            });
			},
			deleteRow(index, rows_benih_raw_at) {
	            var idx = this.permohonan_dokumen.indexOf(rows_benih_raw_at);
				if(rows_benih_raw_at?.id){
					this.deleted.push(rows_benih_raw_at?.id);
				}
	            if (idx > -1) {
	                this.permohonan_dokumen.splice(idx, 1);
	            }
				console.log(this.deleted);
	        },
		},
		created() {
            Swal.fire({
                title: '<i class="fa fa-refresh fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
                // allowOutsideClick: false,
            });
            this.loadingTable = true;
            axios.get(this.apiLink + "api/crud/tujuan_penggunaan_benih/set_dokumen_permohonan", {
				params: {
					tujuan_penggunaan_benih_id: this.idTujuanBenih
				},
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var dokumen_permohonan = response.data;
                var dokumen_permohonan_data = dokumen_permohonan.data[0];
                // console.log(dokumen_permohonan_data);
                if (dokumen_permohonan.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: dokumen_permohonan.data.message
                    });
                }
                else{
					swal.close();
					let clear_data = [];
					$.each(dokumen_permohonan_data, function (indexInArray, valueOfElement) { 
						clear_data.push({
							'id': valueOfElement.id,
							'dokumen': valueOfElement.dokumen_persyaratan_permohonan,
							'urutan': valueOfElement.urutan
						})
					});
                    this.loadingTable = false;
                    this.permohonan_dokumen = clear_data;
					console.log(clear_data);
                    
                }
            });
			axios.get(this.apiLink + "api/master/dokumen_persyaratan_permohonan", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_dokumen_permohonan = response.data;
                var res_dokumen_permohonan_data = res_dokumen_permohonan.data;
                if (res_dokumen_permohonan.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_dokumen_permohonan.data.message
                    });
                }
                else{
                    this.list_dokumen_permohonan = res_dokumen_permohonan_data.master;
                }
            });
			axios.get(this.apiLink + "api/crud/tujuan_penggunaan_benih", {
                params: {
                    id: this.idTujuanBenih,
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_bentuk_benih = response.data;
                var res_bentuk_benih_data = res_bentuk_benih.data[0].data[0];
                if (res_bentuk_benih.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_bentuk_benih.data.message
                    });
                }
                else{
                    Swal.close();
                    this.tpb_nama = res_bentuk_benih_data.tpb_nama;
                    
					
                }
            });
        }
	};
</script>